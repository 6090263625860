import React from 'react'

export default function useSearchParams() {
  const [params, setParams] = React.useState(new URLSearchParams(''))

  React.useEffect(() => {
    const query = window.location.search
    setParams(new URLSearchParams(query))
  }, [])

  return params
}
