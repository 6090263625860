import React from 'react'
import { bs } from '../shevy'

export function Input({
  label,
  value,
  onChange,
  required = false,
  type = 'text',
  ...rest
}) {
  return (
    <div
      css={{
        fontFamily: 'var(--fonts-catamaran)',
      }}
    >
      <label css={{ display: 'flex', flexDirection: 'column' }} htmlFor={label}>
        <span css={{ fontWeight: 'bold', marginBottom: bs(0.25) }}>
          {label}
          {required && '*'}
        </span>
        <input
          css={{
            backgroundColor: 'var(--components-inputs-background)',
            border: 'none',
            color: 'var(--components-inputs-text)',
            padding: `${bs(0.375)} ${bs(0.5)}`,
          }}
          id={label}
          type={type}
          value={value}
          onChange={onChange}
          required={required}
          {...rest}
        />
      </label>
    </div>
  )
}

const inputHooksFactory = (formatter = x => x) => initialValue => {
  const [value, setValue] = React.useState(formatter(initialValue))

  const onChange = React.useCallback(e => {
    setValue(formatter(e.target.value))
  }, [])

  return [value, onChange, setValue]
}

export const useInput = inputHooksFactory()
export const useNumberInput = inputHooksFactory(Number)
