import React from 'react'
import Button from '../components/Button'
import { Input } from '../components/Inputs'
import MainInner from '../components/MainInner'
import Seo from '../components/Seo'
import Spacer from '../components/Spacer'
import { useAuthContext } from '../contexts/AuthContext'
import useSearchParams from '../hooks/useSearchParams'
import { bs } from '../shevy'

export default function ResetPassword() {
  const params = useSearchParams()
  const accessToken = params.get('access_token')

  return (
    <>
      <Seo title="Reset Password" />
      <MainInner>
        <h2>Forgot Password</h2>
        {accessToken ? <Form accessToken={accessToken} /> : <Error />}
      </MainInner>
    </>
  )
}

function Form({ accessToken }) {
  const [, { updatePasswordViaToken }] = useAuthContext()
  const [state, setState] = React.useState('IDLE')
  const [newPassword, setNewPassword] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    setState('PENDING')

    try {
      const { error } = await updatePasswordViaToken(accessToken, newPassword)

      if (error) {
        console.error(error)
        setState('FAILURE')
        setErrorMessage(error.message)
        return
      }

      setState('SUCCESS')
    } catch (error) {
      console.error(error)
      setState('FAILURE')
      setErrorMessage(error.message)
      return
    }
  }

  const handleNewPassword = React.useCallback(e => {
    setNewPassword(e.target.value)
  }, [])

  return (
    <>
      <p>Create a new password for your account.</p>
      <div css={{ backgroundColor: 'var(--colors-offset)', padding: bs() }}>
        {state === 'FAILURE' && (
          <div>
            <p>Error: {errorMessage}</p>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Spacer bottom={1}>
            <Input
              label="New password"
              onChange={handleNewPassword}
              type="password"
              value={newPassword}
            />
          </Spacer>

          <Button
            disabled={state === 'PENDING'}
            onClick={handleSubmit}
            type="submit"
          >
            Submit
          </Button>
        </form>
        {state === 'SUCCESS' && <Spacer top={1}>Success!</Spacer>}
      </div>
    </>
  )
}

function Error() {
  return (
    <p>
      Looks like you're trying to reset a password but something went wrong.
    </p>
  )
}
